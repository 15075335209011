.cart-drop, .mbr-drop {
    visibility: hidden;
    opacity: 0;
    transition:visibility 200ms ease-in-out, opacity 200ms ease-in-out;
}

.cart-div:hover > .cart-drop {
    visibility: visible;
    opacity: 1;
}

.cat-drop {
    visibility: hidden;
    opacity: 0;
    transition:visibility 200ms ease-in-out, opacity 200ms ease-in-out;
}

.cat-btn:hover > .cat-drop {
    visibility: visible;
    opacity: 1;
}

.mbr-btn:hover > .mbr-drop  {
    visibility: visible;
    opacity: 1;
}